import React, { Component, Fragment } from 'react';
import { TweenLite, Power3 } from 'gsap';
import { NavLink } from 'react-router-dom';
import keyImage from '../../../src/images/key-isolated.png';
import packshot from '../../../src/images/packages.png';
import packshotfr from '../../../src/images/packages-fr.png';
import EnterBtn from '../../images/enter-btn-long.png';
import EnterBtnHover from '../../images/enter-btn-long-hover.png';
import ReactGA from 'react-ga';
import Cookie from 'js-cookie';

import '../../styles/pages/_coming-soon.scss';

//langualge file
import translate from '../../i18n/translate';

export default class ComingSoon extends Component{

    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);


        //GSAP animation for the heading elements
        const { headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText } = this;
        TweenLite.staggerFrom([headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut
        },.1)

        //add class to front page
        document.querySelector('#glow').classList.add('homepage')
    }

    componentWillUnmount(){
        //remove class from front page
        document.querySelector('#glow').classList.remove('homepage')
    }

    render(){
        const lang = Cookie.get('lang-preferance');
        return(
            <Fragment>
                <div className="main-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 coming-soon-text-container text-center">
                                <div className="heading-text">
                                    <h1 className="unlock-the-secret" ref={el => {this.headingOne = el}}>{translate('homepage.unlock')}</h1>
                                    <h1 className="chance-to-win" ref={el => {this.headingTwo = el}}><em>{translate('homepage.unlock.italic')}</em> {translate('homepage.win')}</h1>
                                    <h1 className="prize-money" ref={el => {this.headingThree = el}}>{translate('homepage.oneofthree',{sup: chunks => <sup>{chunks}</sup>,small: chunks => <small>{chunks}</small>})}</h1>
                                    <h1 className="prize-money" ref={el => {this.headingFour = el}}>{translate('homepage.prize',{sup: chunks => <sup>{chunks}</sup>})}</h1>
                                    <h1 className="plus-cash" ref={el => {this.headingFive = el}}>{translate('homepage.pluscash')}</h1>
                                </div>
                                <div className="coming-soon-key-container d-md-none">
                                    <img src={keyImage} className="" alt="caramilk key" />
                                </div>
                                <p className="coming-soon" ref={el => {this.bodyText = el}}>{translate('contest-start', {br: <br />,em: chunks => <em>{chunks}</em>})}</p>
                                <div className="packshot-group">
                                    {lang === 'fr-CA' ? <img src={packshotfr} className="packshot" alt="caramilk packs" /> : <img src={packshot} className="packshot" alt="caramilk packs" />}
                                </div>
                                <div className="enter-contest-btn">
                                    <NavLink exact activeClassName="selectedLink" to='/pastwinners' tabIndex="0">
                                        <button className="winner-btn" tabIndex="0">
                                            <img src={EnterBtn} alt="" className="fill" />
                                            <img src={EnterBtnHover} alt="" className="hover" />
                                            <p>{translate('view2020winner')}</p>
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="col-md-4 col-md-push-1 d-none d-md-block coming-soon-key-container">
                                <img src={keyImage} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="container disclaimer-wrap">
                        <div className="row">
                            <div className="col-md-12 disclaimer">
                            <p>{translate('legal')} <NavLink exact to="/rules">{translate('legal.link')}</NavLink></p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}