import React, { Component, Fragment } from 'react';
import Particles from 'react-particles-js';
import floorImage from '../../../src/images/floor.png';
import floorBokeh1 from '../../../src/images/floor-bokeh-1.png';
import floorBokeh2 from '../../../src/images/floor-bokeh-2.png';
import fadeImage from '../../../src/images/fade-withsparkles.jpg';
import keyGlowStatic from '../../../src/images/key-glow-stationary.png';
import keyGlowRays from '../../../src/images/key-glow-rays.png';
import keyGlowSparkle from '../../../src/images/key-glow-sparkles-2.png';
import keyGlowSparkleDepth from '../../../src/images/key-glow-sparkles-1.png';
import keyGlowTwinkle from '../../../src/images/key-glow-twinkle.png';

import '../../styles/_global.scss';

class Canvas extends Component{

    componentDidMount() {
        // var pstate = this.particles.state;
        // console.log(this);
        const canvas = this.canvas;
        const ctx = canvas.getContext("2d");
        const floor = this._floorimage;
        const fade = this._fadeimage;
        const bg = this.bg;

        floor.onload = () => {
            // console.log(bg);
            bg.style.width = document.body.clientWidth;
            bg.style.height = document.body.clientHeight;
            //ctx.drawImage(floor, 0, canvas.height-floor.height);
            //ctx.drawImage(fade, 0, 0);
            // const pattern = ctx.createPattern(img, 'repeat');
            // ctx.fillStyle = pattern;
            // ctx.fillRect(0, 0, canvas.width, canvas.height);
            //ctx.font = "40px Courier"
            //ctx.fillText(this.props.text, 210, 75)
        }
    }

    render(){
        return(
            <div id="bg" ref={el => {this.bg = el}}>
                <Fragment>
                    <canvas ref={el => {this.canvas = el}} id="canvas_bg" aria-label="background twinkles"/>
                    <img ref={el => {this._fadeimage = el}} src={fadeImage} id="fade" alt="" />
                    <img ref={el => {this._floorimage = el}} src={floorImage} className="floor" id="floor" alt="" />
                    <img ref={el => {this._floorimage = el}} src={floorBokeh1} className="floor bokeh" id="bokeh1" alt="" />
                    <img ref={el => {this._floorimage = el}} src={floorBokeh2} className="floor bokeh" id="bokeh2" alt="" />
                    <div id="glow_wrapper_wrapper">
                        <div id="glow_wrapper">
                            <div ref={el => {this._glow = el}} id="glow" alt="">
                                <img src={keyGlowRays} id="rays" alt="" className="glowbg slowspin" />
                                <img src={keyGlowSparkleDepth} id="sparkles2" alt="" className="glowbg medspin" />
                                <img src={keyGlowSparkle} id="sparkles" alt="" className="glowbg quickspin" />
                                <img src={keyGlowStatic} id="staticglow" alt="" className="glowbg pulse" />
                                <div id="twinkle_wrapper" className="glowbg quickspin">
                                    <img src={keyGlowTwinkle} id="twinkle1" className="twinkle" alt="" />
                                    <img src={keyGlowTwinkle} id="twinkle2" className="twinkle" alt="" />
                                    <img src={keyGlowTwinkle} id="twinkle3" className="twinkle" alt="" />
                                    <img src={keyGlowTwinkle} id="twinkle4" className="twinkle" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            
                <Particles ref={el => {this.particles = el}} 
                    params={{
                        "particles": {
                            "collisions": {
                                "enable":false
                            },
                            "color":{
                                "value": "#fff4d5"
                            },
                            "shadow":{
                                "blur":3,
                                "color":{
                                    "value": "#e2b52f"
                                },
                                "enable":true
                            },
                            /*"stroke": {
                                "color": {
                                    "value": "#ffda17"
                                },
                                "width": 2,
                                "opacity": .5
                            },*/
                            "number": {
                                "value": 40,
                                "density": {
                                    "enable": false
                                }
                            },
                            "size": {
                                "value": 4,
                                "random": true
                            },
                            "move": {
                                "direction": "none",
                                "enable":true,
                                "out_mode": "out",
                                "random":true,
                                "speed":2
                            },
                            "line_linked": {
                                "enable": false
                            }
                        },
                        "interactivity": {
                            "modes": {
                                "remove": {
                                    "particles_nb": 10
                                }
                            }
                        }
                    }} />
            </div>
        )
    }
}

export default Canvas;