import {LOCALES} from '../locales.js';

export default {
    [LOCALES.FRENCH]: {

        //global
        'site.title' : 'Concours le Secret de la Caramilk',
        'close': 'Proche',

        //header

        //nav
        'menu.item.home' : 'ACCUEIL',
        'menu.item.winner' : 'ANCIENS GAGNANTS',
        'menu.item.1': 'PARTICIPATION',
        'menu.item.2': 'PRIX',
        'menu.item.3': 'FAQ',
        'menu.item.4': 'RÈGLEMENT',
        'caramilk.key' : 'Clé Caramilk',

        //footer
        'footer.link1' : 'Règlement',
        'footer.link2' : 'Politique de confidentialité',
        'footer.link3' : 'Modalités',
        'footer.link4' : 'Politique d’accessibilité',
        'footer.link5' : 'Communiquez avec nous',

        //homapge
        'homepage.unlock': 'VOUS POURRIEZ DÉVERROUILLER',
        'homepage.unlock.italic': 'le SECRET DE LA CARAMILK',
        'homepage.win' : 'ET',
        'homepage.oneofthree' : 'GAGNER<sup>*</sup> 1 <small>des</small> 3',
        'homepage.prize': 'PRIX DE 10 000 <sup>$</sup>',
        'homepage.pluscash': 'PLUS DES PRIX QUOTIDIENS EN ARGENT',
        'homepage.cta' : 'DÉFILEZ POUR EN SAVOIR PLUS',
        'homepage.howtoenter' : 'COMMENT PARTICIPER?',
        'homepage.step1head' : 'ÉTAPE 1',
        'homepage.step1' : 'Achetez un produit Caramilk spécialement marqué.',
        'homepage.step2head' : 'ÉTAPE 2',
        'homepage.step2' : 'Recherchez les codes à l’intérieur de votre emballage Caramilk participant.',
        'homepage.step3head' : 'ÉTAPE 3',
        'homepage.step3' : 'Entrez les codes sur le site caramilk.ca.',

        'enterContestText' : 'Inscrivez le code qui se trouve{br}à l’intérieur de votre emballage{br}Caramilk participant.',
        'contestClosedHead' : 'CONCOURS TERMINÉ',
        'contestClosedText' : 'Merci de votre participation! Le concours « Vous pourriez déverrouiller le Secret et GAGNER 100 000 $ » est maintenant terminé, mais continuez à tenter de percer le Secret ',
        'contestClosedLink' : 'https://mysteredelacaramilk.com/',
        'contestClosedCTA' : 'ici',

        'enterNow' : 'Participez maintenant',

        'legal' : '*Aucun achat nécessaire. Ouvert aux résidents du Canada (majeurs). Le Concours débute le 25 juillet 2022 ( 00 h 00 HE) et se termine le 2 novembre 2022 (23 h 59 59 sec. HE). Trois grands prix à gagner (10 000 $ CA chacun). 202 prix quotidiens en argent disponibles (2 par jour; 100 $ CA chacun). Les chances de gagner dépendent du nombre et de la date des inscriptions admissibles. Question d’habileté requise. Participation en ligne et règlement sur ',
            'legal.link' : 'www.caramilk.ca.',

        // 2020 Winners page
        'winners.title' : 'RENCONTREZ LES PERSONNES GAGNANTES DE NOS ANCIENS CONCOURS!',
        'winners.subtitle' : 'FÉLICITATIONS JESSICA!',
        'winners.body' : 'Toutes nos félicitations à Jessica de Verdun au Québec qui a déverrouillé le secret et gagné le Grand prix 2020!',
        'winners.video' : 'https://www.youtube.com/embed/-NnE8n8AIUU',
        'winners.alt' : 'Jessica avec son chèque de 100 000 $',
        'winners.subtitle2' : 'FÉLICITATIONS JOANNE!',
        'winners.body2' : 'Toutes nos félicitations à Joanne de Kitchener en Ontario qui a déverrouillé le secret et gagné le Grand prix 2021!',
        'winners.video2' : 'https://www.youtube.com/embed/NQI3RV2Th-A',
        'winners.alt2' : 'Joanne holding $100,000 cheque',

        'comingsoon' : 'À VENIR!',
        'unlock' : 'VOUS POURRIEZ DÉVERROUILLER',
        'secret' : 'LE SECRET DE LA CARAMILK',
        'contest-start': 'Recherchez les produits Caramilk participants.{br}Le Concours commence <em>le juillet 25, 2022</em>.',
        'view2020winner' : 'VOIR LES ANCIENS GAGNANTS',
        'packlabel' : '4 codes{br}inclus',

        //enter contest page
        'step1': 'Étape 1',
        'enterPin': 'INSCRIRE LE CODE',
        'enterKeyDescription': 'Inscrivez le code unique qui se trouve à l’intérieur de votre emballage Caramilk pour courir la chance de trouver une clé virtuelle!',
        'popupBodytext' : 'Les codes uniques à 12 caractères se trouvent au verso du devant de l’emballage des tablettes promotionnelles Caramilk de 50 g et de 78 g.',
        'pin': 'CODE',
        'nextStep': 'Étape Suivante',

        'step2': 'Étape 2',
        'enterInfo': 'RENSEIGNEMENTS PERSONNELS',
        'enterInfoDescription': 'Veuillez fournir vos renseignements personnels pour participer et courir la chance de trouver une clé virtuelle!',
        'firstName': 'Prénom',
        'lastName': 'Nom',
        'emailAddress': 'Adresse électronique',
        'infoForm.age': 'Je confirme avoir atteint l’âge de la majorité dans ma province ou mon territoire de résidence*',
        'infoForm.rules' : 'J’ai lu le ',
            'infoForm.rules.link1' : 'Règlement du concours',
            'infoForm.rules2' : ' et la ',
            'infoForm.rules.link2' : 'Politique de confidentialité',
            'infoForm.rules3' : ' de Mondelēz Canada et j’accepte de mcy conformer*',
        'infoForm.optin' : 'Je confirme que je veux recevoir des informations sur les promotions Mondelēz Canada à venir (optionnel)',
        'requiredFields' : '*Champs requis',
        'submit': 'Soumettre',

        //winning page
        'congratulation' : 'VOUS AVEZ ÉTÉ SÉLECTIONNÉ COMME GAGNANT POTENTIEL D’UN PRIX QUOTIDIEN DE 100\u00A0$\u00A0EN\u00A0ARGENT!',
        'winningPage.body' : 'Avant d’être déclaré officiellement gagnant, le détenteur d’un code gagnant doit répondre correctement, sans aide, à la question réglementaire d’arithmétique ci-dessous.',
        'winningPage.question' : '(5X5) - 21, puis additionner 6 =',
        'winningPage.cta' : 'SOUMETTRE',
        'winningPage.legal' : 'Veuillez conserver votre code/emballage, car le Commanditaire du concours peut exiger la production du code gagnant avant d’attribuer le prix.',

        'winningPage.heading1' : 'FÉLICITATIONS!{br}VOUS ÊTES LE GAGNANT OU LA GAGNANTE D’UN PRIX QUOTIDIEN DE 100\u00A0$\u00A0EN\u00A0ARGENT!',
        'winningPage.body2' : 'You will receive the $100 prize via e-transfer to the email provided within 2-3 weeks.{br}{br}If you do not receive the e-transfer email within 2-3 weeks, please check your junk mailbox or contact us at ',
        'winningPage.body2a' : '. Plus you’ve been entered into the next draw for the Grand Prize!',
        
        'contact-email': 'customercare@caramilk.ca',

        'winningPage.heading3' : 'DÉSOLÉ, MAUVAISE RÉPONSE',
        'winningPage.body3' : 'Essayez de nouveau avec un autre code',

        'winningPage.heading2' : 'PARTAGEZ LA NOUVELLE',
        'download' : 'Télécharger',

        //losing page
        'tryAgain' : 'MERCI DE PARTICIPER AU CONCOURS',
        'tryAgain.body' : 'Vous êtes inscrit au prochain tirage pour GAGNER* 1 des 3 PRIX de 10 000 $.{br}{br}Vous n’avez pas gagné le prix quotidien, mais il y a encore d’autres prix quotidiens en argent à gagner.',
        'tryAgain.body.pin' : 'Inscrivez un autre code se trouvant à l’intérieur des emballages Caramilk spécialement marqués pour avoir une autre chance de gagner.',
        'tryAgain.btn' : 'ESSAYEZ DE NOUVEAU', 
        'tryAgain.image1' : 'Tablette Caramilk de 50 g',
        'tryAgain.image2' : 'Caramilk en emballage multiple',
        'tryAgain.heading2' : 'PARTAGEZ LE PLAISIR',
        'tryAgain.body2' : 'Ne gardez pas le secret! Parlez du Concours à vos amis et faites-leur savoir qu’il y a encore des prix à gagner!',
        'tryAgain.body2.nokey' : 'Ne gardez pas le secret! Partagez le concours avec vos amis.',
        'tryAgain.body3' : 'Vous voulez partager sur Instagram? Cliquez sur le bouton Télécharger ci-dessous et obtenez une image que vous pourrez afficher dans votre compte Instagram.',

        //Prize Details
        'prizeDetails' : 'DÉTAILS DU PRIX',

        'prizeDetails-heading1' : 'GRAND PRIX',
        'prizeDetails-subheading1' : '1 des 3 prix en argent de 10\u00a0000\u00a0$',
        'prizeDetails-body1' : 'Dates des tirages;<li>1er septembre 2022</li><li>1er octobre 2022</li><li>3 novembre 2022</li>',

        'prizeDetails-heading2' : 'Des centaines de PRIX QUOTIDIENS EN ARGENT À GAGNER!',
        'prizeDetails-body2' : '2 par jour!{br}{br}Chaque gagnant confirmé d’un prix quotidien recevra un prix en argent de 100\u00a0$ et courra la chance de gagner 1 des 3 grands prix de 10\u00a0000\u00a0$.',

        //faq page
        'faq-heading-text1': 'Bien que le ',
            'faq-heading-link': 'Règlement officiel',
        'faq-heading-text2': ' couvre tous les détails de cette promotion, votre question et sa réponse se trouvent peut-être ci-dessous.',

        'q1': 'Q. Quelle est la durée de cette promotion?',
        'a1': 'R. Caramilk Déverrouillez le secret (la « promotion ») débute le 25 juillet 2022 à 0 h heure de l’Est (“HE”) et se termine le 2 novembre 2022 à 23 h 59 min 59 s HE.',
        
        'q2': 'Q. En quoi consistent les prix de cette promotion?',
        'a2': 'R.  Il y a trois (3) grands prix de 10 000 $ à gagner plus deux cent deux (202) prix quotidiens de 100 $. Chaque personne gagnante confirmée recevra un prix en argent de $ CAN et sera inscrite au tirage au sort pour courir la chance de gagner l’un des trois (1 des 3) grands prix de 10 000 $.',
        
        'q2-b' : 'Q. Comment puis-je participer sans faire d’achat?',
        'a2-b' : 'R. <b><u>Aucun achat nécessaire</u></b> : Pour obtenir un (1) code, jusqu’à épuisement des stocks, sans acheter de produit admissible, inscrivez votre prénom, votre nom, votre numéro de téléphone, votre adresse postale complète (y compris le code postal) et votre âge sur une feuille de papier blanc ordinaire et postez-la (dans une enveloppe suffisamment affranchie au Canada) accompagnée de ce qui suit (i) une phrase unique et originale d’au moins cinquante (50) mots sur la question suivante : COMMENT PENSEZ-VOUS QUE LE CARAMEL SE TROUVE DANS LA TABLETTE CARAMILK? et (ii) une enveloppe prépayée et affranchie en quantité suffisante à l’adresse suivante : CONCOURS SECRET CARAMILK, 6 – 6150 Highway 7, Suite #154, Woodbridge, ON L4H 0R6 (chacune de ces demandes étant une « <b>demande</b> »). À la réception d’une demande valide conformément au présent règlement officiel, l’administrateur vous enverra par la poste un (1) code choisi au hasard, jusqu’à épuisement des stocks, par demande unique et originale par enveloppe suffisamment affranchie au Canada. Pour être admissible, toute demande doit (i) être reçue séparément dans une seule enveloppe suffisamment affranchie au Canada (c.-à-d. que plusieurs demandes dans la même enveloppe seront annulées); et (ii) être oblitérée pendant la période de promotion et reçue au plus tard le 7 octobre 2022 afin de donner au commanditaire suffisamment de temps pour renvoyer le code par courrier ordinaire. Voir ',
            'a2-b.link': 'le règlement complet du Concours',
        'a2-b-bottom' : ' pour plus de détails.',
        
        'q3': 'Q. Combien de fois puis-je inscrire un code?',
        'a3': 'R. Chaque code n’est valide que pour une seule participation.',
        
        'q4': 'Q. Combien de prix puis-je gagner?',
        'a4': 'R. Il y a une limite d’un (1) prix quotidien et d’un Grand prix par personne.',

        'q5': 'Q. J’ai de la difficulté à inscrire un code. Que dois-je faire?',
        'a5': 'R.  Assurez-vous que vous inscrivez le code alpha à 12 chiffres exactement comme indiqué. Assurez-vous que vous utilisez un emballage admissible et spécialement marqué de : ',
            'a5-li1': 'Caramilk 50 g',
            'a5-li2': 'Caramilk Caramel Salé 50 g',
            'a5-li3': 'Caramilk 100 g',
            'a5-li4': 'Caramilk Caramel Salé 100 g',
        'a5-bottom': 'Le code est imprimé à l’intérieur de l’emballage individuel de Caramilk 50 g, Caramilk Caramel Salé 50 g, Caramilk 100 g, Caramilk Caramel Salé 100 g. Veuillez saisir le code sans tiret ni espace.',

        'q6': 'Q. J’ai un code valide, mais je reçois un message d’erreur.',
        'a6': 'R. Veuillez inscrire le code à 12 chiffres sans tiret ni espace. Si cela ne fonctionne toujours pas, veuillez communiquer avec nous à l’adresse ',

        'q7': 'Q. J’ai reçu un message me disant que j’avais déjà participé aujourd’hui, mais ce n’est pas le cas. Que dois-je faire?',
        'a7': 'R. Assurez-vous de visiter directement le site ',
            'a7-link': 'www.caramilk.ca',
        'a7-bottom': ' plutôt que d’y accéder par le biais d’un signet. Vous devriez également effacer la mémoire cache et supprimer les témoins de votre navigateur.',

        'q8': 'Q. Comment saurai-je si je suis un gagnant instantané potentiel?',
        'a8': 'R. Deux cent deux (202) heures aléatoires générées par ordinateur, chacune associée à un (1) des prix quotidiens, seront déterminées pendant la Période du concours. Si un participant soumet une participation admissible à l’heure exacte de l’une (1) des heures aléatoires générées par ordinateur, ou s’il est le premier participant à soumettre une participation admissible après cette heure, il en sera instantanément informé. Les gagnants potentiels devront répondre correctement à une question réglementaire pour être déclarés gagnants officiels. Les gagnants officiels seront contactés au moyen des coordonnées fournies lors de l’inscription et recevront de plus amples détails sur la manière de réclamer leur prix.{br}{br}Les personnes qui n’ont pas gagné de prix quotidien seront toujours inscrites pour courir la chance de gagner 1 des 3 grands prix de 10 000 $ à la fin du Concours, le 2 novembre 2022 à 23 h 59 HE. Les gagnants potentiels seront contactés au moyen des coordonnées fournies lors de l’inscription et recevront de plus amples détails sur la manière de réclamer leur prix.',

        'q9': 'Q. Je crois que j’éprouve des problèmes techniques. Que dois-je faire?',
        'a9': 'R. Parfois, il suffit de mettre à niveau votre navigateur. Vérifiez que vous utilisez une version récente de Chrome, Internet Explorer, Firefox ou Safari. De plus, vous devriez vous assurer d’activer JavaScript. Effacer la mémoire cache et supprimer les témoins de votre navigateur peut également s’avérer efficace.',

        'q10': 'Q. J’ai récemment déménagé ou changé d’adresse électronique. Puis-je modifier mes renseignements personnels pour cette promotion?',
        'a10': 'R. Nous sommes désolés des inconvénients occasionnés, mais en raison du nombre de participations que nous recevons, nous sommes dans l’impossibilité de modifier l’information que vous avez fournie. Si vous êtes sélectionné comme gagnant potentiel pendant la période promotionnelle et qu’il y a un changement à apporter à vos renseignements personnels, écrivez à l’adresse ',
        'a10-2' : ' afin de fournir vos renseignements à jour.  {br}{br} À noter : Si vos renseignements personnels doivent être modifiés pendant la période promotionnelle, nous ne pouvons garantir être en mesure de répondre à votre demande.  {br}{br} Vous avez encore une question? Envoyez-nous un courriel à l’adresse ',
        'a10-3' : ' Veuillez compter jusqu’à trois jours ouvrables pour recevoir une réponse.',

        'q11': 'Q. Pourquoi est-il impossible de fournir un numéro de case postale pour participer ou réclamer mon prix?',
        'a11': 'R. Votre adresse résidentielle nous permet de vérifier votre admissibilité selon votre lieu de résidence. {br} {br} ',
        
        'faq-footer-text': 'Vous avez encore une question? Veuillez remplir ce formulaire. Veuillez compter jusqu’à trois jours ouvrables pour recevoir une réponse.',

        //Rules page
        'rules-page-heading': 'RÈGLEMENT OFFICIEL',
    }
}