import React, { Component, Fragment } from 'react';
import { TweenLite, Power3 } from 'gsap';
import winnerImage from '../../../src/images/2020winner-jessica.jpg';
import winnerImage_fr from '../../../src/images/2020WinnerImageFR.jpg';
import winnerImage2 from '../../../src/images/2021winner-joanne.jpg';
import winnerImage2_fr from '../../../src/images/2021winner-joanne-FR.jpg';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';

import '../../styles/pages/_winner.scss';
import Cookie from 'js-cookie';

import { getContestState } from '../../util';

//button
import EnterBtn from '../../images/enter-btn.png';
import EnterBtnHover from '../../images/enter-btn-hover.png';

//langualge file
import translate from '../../i18n/translate';

export default class Winner extends Component{

    componentDidMount(){
        //GSAP animation for the heading elements
        const { headingOne, hOne, pOne, imgOne, hTwo, pTwo, imgTwo } = this;
        TweenLite.staggerFrom([headingOne, hOne, pOne, imgOne, hTwo, pTwo, imgTwo], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut
        },.1)


        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    componentWillUnmount(){
    }

    render(){
        //checking for language cookie
        const lang = Cookie.get('lang-preferance');
        const contestState = getContestState();
        // const winnervideo = lang === "fr-CA" ? 'https://www.youtube.com/embed/-NnE8n8AIUU'
        //     : 'https://www.youtube.com/embed/NQI3RV2Th-A';

        return(
            <Fragment>
                <div className="main-wrapper">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <div className="page-heading" ref={el => {this.headingOne = el}}>
                                        <h1>{translate('winners.title')}</h1>
                                    </div>
                                    <div className="page-body">
                                        <h2 ref={el => {this.hOne = el}}>{translate('winners.subtitle')}</h2>
                                        <p ref={el => {this.pOne = el}}>{translate('winners.body')}</p>
                                    
                                        {/* <div className="video-frame">
                                        <iframe src={winnervideo} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div> */}

                                        <img ref={el => {this.imgOne = el}} src={lang === "fr-CA" ? winnerImage_fr : winnerImage} className="winner" alt="Jessica holding $100,000 cheque"/>

                                        <h2 ref={el => {this.hTwo = el}}>{translate('winners.subtitle2')}</h2>
                                        <p ref={el => {this.pTwo = el}}>{translate('winners.body2')}</p>
                                        <img ref={el => {this.imgTwo = el}} src={lang === "fr-CA" ? winnerImage2_fr : winnerImage2} className="winner" alt="Jessica holding $100,000 cheque"/>
                                    </div>
                                    { contestState===0 && <div className="enter-contest-btn">
                                        <NavLink exact to="/enter-contest">
                                            <button className="enter-btn" tabIndex="0">
                                                <img src={EnterBtn} alt="enter contest button" className="fill" />
                                                <img src={EnterBtnHover} alt="enter contest button" className="hover" />
                                                <p>{translate('enterNow')}</p>
                                            </button>
                                        </NavLink>
                                    </div> }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        );
    }
}