import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';
import Cookie from 'js-cookie';

//buttons
import EnterBtn from '../../images/enter-btn.png';
import EnterBtnHover from '../../images/enter-btn-hover.png';

//langualge file
import translate from '../../i18n/translate';

import { getContestState } from '../../util';

//css for faq page
import '../../styles/pages/_faq.scss';

class Faq extends Component{

    componentDidMount(){
        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render(){
        const langPreferance = Cookie.get('lang-preferance');
        const contestState = getContestState();
        return(
            <Fragment>
                <div className="main-wrapper">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <div className="page-heading">
                                        <h1>FAQ</h1>
                                    </div>
                                    <div className="page-body faq">
                                        <p>
                                            {translate('faq-heading-text1')}  
                                            <NavLink exact to="/rules">{translate('faq-heading-link')}</NavLink>
                                            {translate('faq-heading-text2')}
                                        </p>
                                        <div className="qa">
                                            <h3 className="q">{translate('q1')}</h3>
                                            <p className="a">{translate('a1')}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q2')}</h3>
                                            <p className="a">
                                                {translate('a2')}
                                            </p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q2-b')}</h3>
                                            <p className="a">
                                                {translate('a2-b',{u: chunks => <u>{chunks}</u>,b: chunks => <b>{chunks}</b>})} 
                                                <NavLink to="/rules" exact>{translate('a2-b.link')}</NavLink>
                                                {translate('a2-b-bottom')} 
                                            </p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q3')}</h3>
                                            <p className="a">{translate('a3')}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q4')}</h3>
                                            <p className="a">{translate('a4')}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q5')}</h3>
                                            <p className="a">
                                                {translate('a5')}
                                                    <li>{translate('a5-li1')}</li>
                                                    <li>{translate('a5-li2')}</li>
                                                    <li>{translate('a5-li3')}</li>
                                                    <li>{translate('a5-li4')}</li>
                                                {translate('a5-bottom')}
                                            </p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q6')}</h3>
                                            <p className="a">{translate('a6')} <a href={langPreferance === 'fr-CA' ? 'mailto:service@caramilk.ca' : 'mailto:customercare@caramilk.ca'}>{translate('contact-email')}</a></p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q7')}</h3>
                                            <p className="a">{translate('a7')} <NavLink exact to="/">{translate('a7-link')}</NavLink> {translate('a7-bottom')}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q8')}</h3>
                                            <p className="a">{translate('a8',{br: <br />})}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q9')}</h3>
                                            <p className="a">{translate('a9')}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q10')}</h3>
                                            <p className="a">
                                                {translate('a10', {br: <br/>})}
                                                <a href={langPreferance === 'fr-CA' ? 'mailto:service@caramilk.ca' : 'mailto:customercare@caramilk.ca'}>{translate('contact-email')}</a>
                                                {translate('a10-2', {br: <br/>})}
                                                <a href={langPreferance === 'fr-CA' ? 'mailto:service@caramilk.ca' : 'mailto:customercare@caramilk.ca'}>{translate('contact-email')}</a>.
                                                {translate('a10-3', {br: <br/>})}
                                            </p>
                                        </div>

                                        <div className="qa">
                                            {/* <p className="a">{translate('faq-footer-text')}</p> */}
                                        </div>
                                        
                                    </div>
                                    { contestState===0 && <div className="enter-contest-btn">
                                        <NavLink exact to="/enter-contest">
                                            <button className="enter-btn" tabIndex="0">
                                                <img src={EnterBtn} alt="enter contest button" className="fill" />
                                                <img src={EnterBtnHover} alt="enter contest button" className="hover" />
                                                <p>{translate('enterNow')}</p>
                                            </button>
                                        </NavLink>
                                    </div> }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }
}

export default Faq;