import React, { Fragment, Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
import Cookie from 'js-cookie';
import { Helmet } from 'react-helmet';
import './App.scss';
import { I18nProvider, LOCALES } from './i18n'; //language files
import ReactGA from 'react-ga'; //google analytics
import ScrollToTop from './component/ScrollToTop'; // scroll restoration
import ComingSoon from './component/pages/ComingSoon';
import ContestClosed from './component/pages/ContestClosed';
import Canvas from './component/canvas/Canvas';
import Header from './component/header/Header';
import Footer from './component/header/Footer';
import Winner from './component/pages/Winner';
import Homepage from './component/pages/Homepage';
import EnterContest from './component/pages/EnterContest';
import PrizeDetails from './component/pages/PrizeDetails';
import Faq from './component/pages/Faq';
import Rules from './component/pages/Rules';
// import YouWin from './component/pages/YouWon';
// import YouLose from './component/pages/YouLose';
// import translate from './i18n/translate';
import { getContestState } from './util';
// import {useLocation} from "react-router-dom";



class App extends Component {

    state = {
      locale : LOCALES.ENGLISH,
      contestState : getContestState(),
      activeEN: true
    }

    //Language change
    langChangeHandle = lang => {
        this.setState({
            locale: lang
        })

        this.setState((prevState) => {
            return {activeEN: !prevState.activeEN}
        })

        Cookie.set('lang-selected', true);
        Cookie.set('lang-preferance', lang)
    }

    //checking for cookie
    componentDidMount(){
        //initiate google analytics
        ReactGA.initialize('UA-74046701-10');
        //ReactGA.pageview(window.location.pathname + window.location.search);

        const langSelected = Cookie.get('lang-selected');
        const langPreferance = Cookie.get('lang-preferance');
        if (langSelected){
            this.setState({
                locale: langPreferance
            })

            if(langPreferance === 'en-US'){
                this.setState({
                    activeEN: true
                })
            }else{
                this.setState({
                    activeEN: false
                })
            }
        }
    }

    render(){
        return (
            <I18nProvider locale={this.state.locale}>
                <Router>
                    <ScrollToTop />
                    <Fragment>
                        <Helmet>
                            <title>{this.state.locale === 'fr-CA' ? 'Concours le Secret de la Caramilk' : 'The Caramilk Secret is back!'}</title>
                        </Helmet>
                        <main className={this.state.locale}>
                            <Canvas text={"Test"} />
                            {/* <div id="glow"></div> */}
                            <Header langChangeHandle={this.langChangeHandle} activeEN={this.state.activeEN} />
                            <Switch>
                                {/* home page */}
                                { this.state.contestState===-1 &&
                                    <Route exact path="/" component={ComingSoon} />
                                }
                                { this.state.contestState===-1 &&
                                    <Route exact path="/home" component={ComingSoon} />
                                }
                                { this.state.contestState===0 &&
                                    <Route exact path="/" component={Homepage} />
                                }
                                { this.state.contestState===0 &&
                                    <Route exact path="/home" component={Homepage} />
                                }
                                { this.state.contestState===1 &&
                                    <Route exact path="/" component={ContestClosed} />
                                }
                                { this.state.contestState===1 &&
                                    <Route exact path="/home" component={ContestClosed} />
                                }

                                { this.state.contestState===0 &&
                                    <Route exact path="/enter-contest" component={EnterContest} />
                                }
                                <Route exact path="/prize-details" render={(props) => <PrizeDetails {...props} lang={this.state.locale} />} />
                                <Route exact path="/faq" component={Faq} />
                                <Route exact path="/rules" component={Rules} />
                                <Route exact path="/pastwinners" component={Winner} />
                            </Switch>
                        </main>
                        <Footer lang={this.state.locale} />
                    </Fragment>
                </Router>
            </I18nProvider>
        );
    }
}

export default App;
