import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { OutboundLink } from 'react-ga';
import InjectIntl from 'react-intl-inject';
import Cookie from 'js-cookie';

//social icons
// import twitterIcon from '../../images/header-twitter.png';
import facebookIcon from '../../images/header-facebook.png';
import youtubeIcon from '../../images/header-youtube.png';
import InstagramIcon from '../../images/header-instagram.png';

//header key image
import HeaderKey from '../../images/header-key.png';

//translate file
import translate from '../../i18n/translate';

import { getContestState } from '../../util';

class Nav extends Component{

    //facebook share new window
    fbShare = () => {
        // const { remainingKeys } = this.props;
        const langPreferance = Cookie.get('lang-preferance');
        const siteDomain = window.location.hostname; 
        const shareLink = langPreferance === 'fr-CA' ? `https://www.facebook.com/sharer/sharer.php?u=http://${siteDomain}/social/fr/index.html` :`https://www.facebook.com/sharer/sharer.php?u=http://${siteDomain}/social/en/index.html`
        window.open(shareLink, "Facebook", "width=500,height=500");
    }

    //twitter share new window
    twShare = () => {
        // const { remainingKeys } = this.props;
        const langPreferance = Cookie.get('lang-preferance');
        const siteDomain = window.location.hostname; 
        const shareLink = langPreferance === 'fr-CA' ? `https://twitter.com/intent/tweet?url=http%3A%2F%2F${siteDomain}%2F&text=Vous%20pourriez%20d%C3%A9verrouiller%20le%20secret%20de%20la%20caramilk%20et%20gagner*%20100%20000%20%24.%20Inscrivez%20votre%20NIP%20unique%20qui%20se%20trouve%20dans%20les%20emballages%20promotionnels%20Caramilk%20participants.` : `https://twitter.com/intent/tweet?url=http%3A%2F%2F${siteDomain}%2F&text=You%20could%20unlock%20the%20Caramilk%20secret%20and%20win*%20%24100%2C000.%20Enter%20your%20unique%20PIN%20found%20inside%20your%20participating%20Caramilk%20package.`
        window.open(shareLink, "Facebook", "width=500,height=500");
    }

    render(){

        // const siteOrigin = window.location.origin;
        const disabled = !this.props.menuOpen;
        const contestState = getContestState();
        
        return(
            <Fragment>
                <div className={`nav-wrapper ${this.props.className}`}>
                    <div className='nav-container'>
                        <ul className="nav-list">
                            <li className="nav-list__item"><NavLink exact activeClassName="selectedLink" to='/home' tabIndex={disabled?-1:0} onClick={this.props.menuClickHandler}>{translate('menu.item.home')}</NavLink></li>
                            { contestState===0 && 
                                <li className="nav-list__item"><NavLink exact activeClassName="selectedLink" to='/enter-contest' id="menu-enterContest" tabIndex={disabled?-1:0} onClick={this.props.menuClickHandler}>{translate('menu.item.1')}</NavLink></li>
                            }
                            <li className="nav-list__item"><NavLink exact activeClassName="selectedLink" to='/prize-details' tabIndex={disabled?-1:0} onClick={this.props.menuClickHandler}>{translate('menu.item.2')}</NavLink></li>
                            <li className="nav-list__item"><NavLink exact activeClassName="selectedLink" to='/pastwinners' tabIndex={disabled?-1:0} onClick={this.props.menuClickHandler}>{translate('menu.item.winner')}</NavLink></li>
                            <li className="nav-list__item"><NavLink exact activeClassName="selectedLink" to='/faq' tabIndex={disabled?-1:0} onClick={this.props.menuClickHandler}>{translate('menu.item.3')}</NavLink></li>
                            <li className="nav-list__item"><NavLink exact activeClassName="selectedLink" to='/rules' tabIndex={disabled?-1:0} onClick={this.props.menuClickHandler}>{translate('menu.item.4')}</NavLink></li>
                            <li className="nav-list__item nav-social-icons">
                                
                                <OutboundLink eventLabel="Nav Facebook Exit"
                                    to="https://www.facebook.com/Caramilk-Canada-112203640461094"
                                    target="_blank"
                                    trackerNames={['tracker2']}
                                    tabIndex={disabled?-1:0}>
                                        <button style={{background: 'transparent', border: 'none'}} tabIndex="-1"><img src={facebookIcon} alt="Caramilk facebook"/></button>
                                </OutboundLink>
                                <OutboundLink eventLabel="Nav Instagram Exit"
                                    to="https://www.instagram.com/caramilkcanada/?hl=en"
                                    target="_blank"
                                    trackerNames={['tracker2']}
                                    tabIndex={disabled?-1:0}>
                                        <button style={{background: 'transparent', border: 'none'}} tabIndex="-1"><img src={InstagramIcon} alt="Instagram"/></button>
                                </OutboundLink>
                                <OutboundLink eventLabel="Nav Youtube Exit"
                                    to="https://www.youtube.com/channel/UC_EyxwF4u4e980NU4FTQrBg"
                                    target="_blank"
                                    trackerNames={['tracker2']}
                                    tabIndex={disabled?-1:0}>
                                        <button style={{background: 'transparent', border: 'none'}} tabIndex="-1"><img src={youtubeIcon} alt="Caramilk YouTube"/></button>
                                </OutboundLink>
                                
                            </li>
                            <li className="nav-list__item header-key">
                                <InjectIntl>
                                    {({ intl }) => (
                                        <img src={HeaderKey} alt={intl.formatMessage({ id: 'caramilk.key' })} />
                                    )}
                                </InjectIntl>
                            </li>
                        </ul>   
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Nav;