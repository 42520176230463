import React, { Component, Fragment } from 'react';
import InjectIntl from 'react-intl-inject';
import Cookie from 'js-cookie';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';
import {InlineShareButtons} from 'sharethis-reactjs';

//buttons
import EnterBtn from '../../images/enter-btn.png';
import EnterBtnHover from '../../images/enter-btn-hover.png';
import packshot from '../../../src/images/packages.png';

//images
// import twitterIcon from '../../images/header-twitter.png';
import facebookIcon from '../../images/header-facebook.png';
import downloadBtn from '../../images/download-btn.png';

//import keys 
// import KeysRemaining from '../KeysRemaining';
import youWinKey from '../../images/youwin-key.png';

//social share and :og tags
// import LosingKeySocialShare from '../LosingKeySocialShare';

//css
import '../../styles/pages/_you-lose.scss';
import translate from '../../i18n/translate';

class YouLose extends Component{
    
    state = {
        buttonHover : false
    }

    //Handle Mouse over event for enter now button
    mouseEnter = () =>{
        this.setState(prevState =>{
            return {buttonHover: !prevState.buttonHover}
        })
    }

    //HANDLE MOUSE LEAVE ON THE ENTER NOW BUTTON
    mouseLeave = () =>{
        this.setState(prevState =>{
            return {buttonHover: !prevState.buttonHover}
        })
    }

    //component did mount
    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        // language cookie setting for IG image
        const langSelected = Cookie.get('lang-selected');
        if(!langSelected) {
            Cookie.set('lang-preferance', 'en-US')
        }

        const enterNowButton = document.querySelector('.enter-contest-btn a')
        enterNowButton.addEventListener('click', (e) =>{
            e.preventDefault();
            window.location.reload()
        })

        const menuEnterContest = document.querySelector('#menu-enterContest')
        menuEnterContest.addEventListener('click', (e) =>{
            e.preventDefault();
            window.location.reload()
        })

        const igDownloadBtn = document.querySelector('#ig-download');
        igDownloadBtn.addEventListener('click', (e)=>{
            ReactGA.event({
                category: 'Download',
                action: 'Instagram'
              });
        })
    }

    //facebook share new window
    fbShare = () => {
        ReactGA.event({
            category: 'ShareThis',
            action: 'facebook'
          });

        const { remainingKeys } = this.props;
        const langPreferance = Cookie.get('lang-preferance');
        const siteDomain = 'caramilk.ca'; 
        const shareLink = langPreferance === 'fr-CA' ? `https://www.facebook.com/sharer/sharer.php?u=https://${siteDomain}/?${remainingKeys}cleatrouver` :`https://www.facebook.com/sharer/sharer.php?u=https://${siteDomain}/?${remainingKeys}keyleft`
        window.open(shareLink, "Facebook", "width=500,height=500");
    }

    //twitter share new window // not used.
    twShare = () => {
        ReactGA.event({
            category: 'ShareThis',
            action: 'twitter'
          });

        const { remainingKeys } = this.props;
        const langPreferance = Cookie.get('lang-preferance');
        const siteDomain = 'caramilk.ca'; 
        const shareLink = langPreferance === 'fr-CA' ? `https://twitter.com/intent/tweet?url=https%3A%2F%2F${siteDomain}%2Fsocial%2Ffr%2F${remainingKeys}keyleft.html&text=Il%20reste%20${remainingKeys}*%20cl%C3%A9s%20%C3%A0%20trouver!%20Visitez%20Caramilk.ca%20pour%20participer!` : `https://twitter.com/intent/tweet?url=https%3A%2F%2F${siteDomain}%2Fsocial%2Fen%2F${remainingKeys}keyleft.html&text=There%20are%20still%20${remainingKeys}*%20keys%20to%20be%20found!%20Visit%20Caramilk.ca%20to%20enter!`
        window.open(shareLink, "Facebook", "width=500,height=500");
    }

    render(){

        const { remainingKeys } = this.props; 
        // const remainingKeys = 5; 
        // const currentPage = window.location.href;
        const siteDomain = 'caramilk.ca'; 
        const langPreferance = Cookie.get('lang-preferance');

        //for ig image 
        let langPref;
        if (langPreferance === 'fr-CA'){
            langPref = 'FR'
        } else {
            langPref = 'EN'
        }

        let twitterTitle;
        if(langPreferance === 'fr-CA' && remainingKeys < 1){
            twitterTitle = `Le Secret de la Caramilk est de retour! Visitez Caramilk.ca pour en savoir plus!`
        } else if (langPreferance === 'fr-CA' && remainingKeys > 1) {
            twitterTitle = `Percez le Secret pour courir la chance de GAGNER 100 000 $. Il reste ${remainingKeys}* clés à trouver! Visitez Caramilk.ca pour participer!`
        } else if ( langPreferance === 'fr-CA' && remainingKeys === 1 ){
            twitterTitle = `Percez le Secret pour courir la chance de GAGNER 100 000 $. Il reste ${remainingKeys}* clé à trouver! Visitez Caramilk.ca pour participer!`
        } else if (langPreferance === 'en-US' && remainingKeys < 1){
            twitterTitle = `The Caramilk Secret is back! Learn more at Caramilk.ca`
        } else if (langPreferance === 'en-US' && remainingKeys > 1){
            twitterTitle = `Unlock the Secret for a Chance to WIN $100,000. There are still ${remainingKeys}* keys to be found! Visit Caramilk.ca to enter!`
        } else if( langPreferance === 'en-US' && remainingKeys === 1 ){
            twitterTitle = `Unlock the Secret for a Chance to WIN $100,000. There is still ${remainingKeys}* key to be found! Visit Caramilk.ca to enter!`
        } else {
            twitterTitle = `Unlock the Secret for a Chance to WIN $100,000. There are still ${remainingKeys}* keys to be found! Visit Caramilk.ca to enter!`
        }

        return(
            <Fragment>
                <div className="main-wrapper you-lose">
                    {/* <LosingKeySocialShare remainingKeys={remainingKeys} /> */}
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <div className="key-container">                                    
                                        <img src={youWinKey} alt="caramilk key"/>
                                        {/* {remainingKeys !== 0 && <KeysRemaining remainingKeys={remainingKeys} />} */}
                                    </div>
                                    <div className="you-win">
                                        <h1>{translate('tryAgain')}</h1>
                                        <p>{translate('tryAgain.body', {br: <br />})}</p>
                                        <p>{translate('tryAgain.body.pin', {br: <br />})}</p>

                                        
                                        <div className="packshot-group stepimage">
                                            <img src={packshot} className="packshot" alt="caramilk packs" />
                                        </div>
                                        
                                        <div className="enter-contest-btn">
                                            <NavLink exact to="/enter-contest">
                                                <button className="enter-btn">
                                                    <img src={EnterBtn} alt="enter contest button" className="fill" />
                                                    <img src={EnterBtnHover} alt="enter contest button" className="hover" />
                                                    <p>{translate('tryAgain.btn')}</p>
                                                </button>
                                            </NavLink>
                                        </div>
                                        
                                        <h3>{translate('tryAgain.heading2')}</h3>
                                        <p>
                                            {remainingKeys !== 0 ? translate('tryAgain.body2') : translate('tryAgain.body2.nokey')}
                                        </p>
                                        <div className="social-container">

                                        <style dangerouslySetInnerHTML={{__html: `
                                            

                                            .st-total{
                                                display:none !important;
                                            }
                                            `}} />
                                        
                                        {remainingKeys < 1 ? (
                                            <InlineShareButtons
                                            config={{
                                                alignment: 'center',  
                                                color: '#ffffff',     
                                                enabled: true,        
                                                font_size: 16,       
                                                labels: 'cta',       
                                                language: 'en',       
                                                networks: [  
                                                'twitter'
                                                ],
                                                padding: 12,    
                                                radius: 4,     
                                                show_total: true,
                                                size: 40,            

                                                url: langPreferance === 'fr-CA' ? `https://${siteDomain}/?fr` : `https://${siteDomain}`,
                                                image: 'http://caramilk.ca/social/img/caramilk_bar_no_keys_left.jpg',        
                                                title: `${twitterTitle}`,            
                                                message: `${twitterTitle}`,     
                                            }}
                                            />
                                        ) : (
                                        <InlineShareButtons
                                            config={{
                                                alignment: 'center',  
                                                color: '#ffffff',     
                                                enabled: true,        
                                                font_size: 16,       
                                                labels: 'cta',       
                                                language: 'en',       
                                                networks: [  
                                                'twitter'
                                                ],
                                                padding: 12,    
                                                radius: 4,     
                                                show_total: true,
                                                size: 40,            

                                                url: langPreferance === 'fr-CA' ? `https://${siteDomain}/?${remainingKeys}cleatrouver` : `https://${siteDomain}/?${remainingKeys}keyleft`,
                                                image: 'http://caramilk.ca/social/img/caramilk_bar_no_keys_left.jpg',        
                                                title: `${twitterTitle}`,            
                                                message: `${twitterTitle}`,     
                                            }}
                                            />
                                        )}

                                            {/* <button onClick={this.twShare} style={{background: 'transparent', border: 'none'}}><img src={twitterIcon} alt="caramilk twitter"/></button> */}
                                            <button onClick={this.fbShare} style={{background: 'transparent', border: 'none'}}><img src={facebookIcon} alt="Caramilk faceook"/></button>
                                        </div>
                                        <p>{translate('tryAgain.body3')}</p>
                                        <a href={ remainingKeys > 0 ? `/social/img/caramilk_instagram_${langPref.toLowerCase()}.jpg` : `/social/img/caramilk_instagram_nokey_${langPref.toLowerCase()}.jpg` } target="_blank" rel="noopener noreferrer" download id="ig-download">
                                            <InjectIntl>
                                                {({ intl }) => (
                                                    <img className="download-btn" src={downloadBtn} alt={intl.formatMessage({ id: 'download' })} />
                                                )}
                                            </InjectIntl>
                                        </a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div> 
            </Fragment>
        );
    }
}

export default YouLose;