import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

//Language files
import { LOCALES } from '../../i18n';

import Nav from './Nav';

//image import
import Logo from '../../images/caramilk-logo.png';
import BurgerBar from '../../images/burger-bar.png';
import closeBtn from '../../images/close-btn.png';

//CSS
import './header.scss';
// import translate from '../../i18n/translate';

class Header extends Component{

    state={
        menuOpen: false,
        langSelect : "FR"
    }

    // //FR click handler
    // clickFrButton = () =>{
    //     const urlPath = window.location.pathname;

    //     //check if 'fr' is in the link
    //     const fr = urlPath.substring(1,3);

    //     if(fr === "fr"){
    //         const englishLink = urlPath.substring(3);
    //         window.location = englishLink;

    //         this.setState({
    //             langSelect: "FR"
    //         })
    //     } else{
    //         const frenchLink = `/fr${urlPath}`;
    //         window.location = frenchLink;

    //         this.setState({
    //             langSelect: "EN"
    //         })
    //     }
    // }

    menuClickHandler = () => {
        this.setState((prevState) => {
            return {menuOpen: !prevState.menuOpen}
        })
    }

    //change langualge 


    render(){

        const { langChangeHandle, activeEN } = this.props;
        const locale = activeEN ? LOCALES.FRENCH : LOCALES.ENGLISH;
        const langLabel = activeEN ? 'FR' : 'EN';
        
        return(
            <Fragment>
                <header>
                    <Nav className={this.state.menuOpen ? "nav-opened" : "nav-closed" } menuClickHandler={this.menuClickHandler} menuOpen={this.state.menuOpen}></Nav>
                    <div className="main-header" id="main-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 lang"></div>
                                <div className="col-md-4 logo">
                                    <h1>
                                        <NavLink exact activeClassName="selectedLink" to='/'>
                                            <img src={Logo} alt="Caramilk Logo"/>
                                        </NavLink>
                                    </h1>
                                </div>
                                <div className="col-md-4 menu">
                                    <button onClick={this.menuClickHandler} style={{display:'block'}} aria-label="Menu">
                                        {this.state.menuOpen ? <img src={closeBtn} alt="close menu"/> : <img src={BurgerBar} alt="open menu"/>}
                                    </button>
                                    <button onClick={() => langChangeHandle(locale)} style={{cursor: 'pointer', background: 'transparent', border: 'none', color: 'white'}}>{langLabel}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </Fragment>
        )
    }
}

export default Header;