import React, { Component, Fragment } from 'react';
// import InjectIntl from 'react-intl-inject';
import Cookie from 'js-cookie';
// import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';
import STQForm from '../forms/STQForm';
import axios from 'axios';

//translate
import translate from '../../i18n/translate';

//images
import youWinKeyEN from '../../images/money-key-EN.png';
import youWinKeyFR from '../../images/money-key-FR.png';

//buttons
import EnterBtn from '../../images/enter-btn.png';
import EnterBtnHover from '../../images/enter-btn-hover.png';

//css
import '../../styles/pages/_you-won.scss';

class YouWon extends Component{

    state = {
        confirmed: 0
    }

    //facebook share new window
    fbShare = () => {
        const { remainingKeys } = this.props;
        const langPreferance = Cookie.get('lang-preferance');
        const siteDomain = 'caramilk.ca'; 
        const shareLink = langPreferance === 'fr-CA' ? `https://www.facebook.com/sharer/sharer.php?u=https://${siteDomain}/social/fr/winner.html` :`https://www.facebook.com/sharer/sharer.php?u=http://${siteDomain}/social/en/${remainingKeys}keyleft.html`
        window.open(shareLink, "Facebook", "width=500,height=500");
    }

    //twitter share new window
    twShare = () => {
        const { remainingKeys } = this.props;
        const langPreferance = Cookie.get('lang-preferance');
        const siteDomain = 'caramilk.ca'; 
        const shareLink = langPreferance === 'fr-CA' ? `https://twitter.com/intent/tweet?url=https%3A%2F%2F${siteDomain}%2Fsocial%2Ffr%2Fwinner.html&text=Il%20reste%20${remainingKeys}%C2%A0cl%C3%A9s%20%C3%A0%20trouver!%20Vous%20pourriez%20D%C3%A9verrouiller%20le%20secret%20et%20gagner.%20Participez%20au%20www.Caramilk.ca.` : `https://twitter.com/intent/tweet?url=http%3A%2F%2F${siteDomain}%2Fsocial%2Fen%2F1keyleft.html&text=There%20are%20still%20${remainingKeys}%20keys%20to%20be%20found!%20You%20could%20Unlock%20the%20Secret%20and%20Win!%20Enter%20at%20caramilk.ca`
        window.open(shareLink, "Facebook", "width=500,height=500");
    }

    correctAnswer = () => {
        const { timestamp } = this.props;
        console.log('you won - correct');
        ReactGA.pageview(window.location.pathname + '?status=stq-correct');
        this.setState({confirmed:1});
        // send call to backend
        axios.post('https://caramilk-contest-staging.herokuapp.com/confirmation', 
        { id:timestamp, correctanswer:true },
        { headers: { 'Content-Type': 'application/json' } });
    }
    incorrectAnswer = () => {
        const { timestamp } = this.props;
        console.log('you won - incorrect');
        ReactGA.pageview(window.location.pathname + '?status=stq-incorrect');
        this.setState({confirmed:-1});
        // send call to backend
        axios.post('https://caramilk-contest-staging.herokuapp.com/confirmation', 
        { id:timestamp, correctanswer:false },
        { headers: { 'Content-Type': 'application/json' } });
    }
    tryAgain = (e) => {
        e.preventDefault();
        window.location.reload()
    }

    componentDidMount(){
        console.log('you won - cdm');
        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render(){
        
        // const currentPage = window.location.href;
        const langPreferance = Cookie.get('lang-preferance');
        const { confirmed } = this.state;

        return(
            <Fragment>

                <div className="main-wrapper">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <div className="key-container">
                                        <img src={langPreferance==='fr-CA'?youWinKeyFR:youWinKeyEN} alt="caramilk key"/>
                                    </div>
                                    {confirmed === 1 &&
                                        <div className="you-win">
                                            <h1>{translate('winningPage.heading1', {br: <br />})}</h1>
                                            <p>
                                                {translate('winningPage.body2', {br: <br />})}
                                                <a href="mailto:customercare@caramilk.ca">customercare@caramilk.ca</a>
                                                {translate('winningPage.body2a', {br: <br />})}
                                            </p>
                                            <p className="legal">{translate('winningPage.legal', {br: <br />})}</p>
                                        </div>
                                    }
                                    {confirmed === 0 &&                                     
                                        <div className="you-win">
                                            <h1>{translate('congratulation', {br: <br />})}</h1>
                                            <p>
                                                {translate('winningPage.body', {br: <br />})}
                                            </p>
                                            <STQForm nextCorrect={this.correctAnswer} nextIncorrect={this.incorrectAnswer} />
                                            <p className="legal">{translate('winningPage.legal', {br: <br />})}</p>
                                        </div>
                                    }
                                    {confirmed === -1 && 
                                        <div className="you-win">
                                        <h1>{translate('winningPage.heading3', {br: <br />})}</h1>
                                        <p>
                                            {translate('winningPage.body3', {br: <br />})}
                                        </p>

                                        <div className="enter-contest-btn">
                                            <button className="enter-btn" onClick={this.tryAgain}>
                                                <img src={EnterBtn} alt="enter contest button" className="fill" />
                                                <img src={EnterBtnHover} alt="enter contest button" className="hover" />
                                                <p>{translate('tryAgain.btn')}</p>
                                            </button>
                                        </div>

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div> 
            </Fragment>
        );
    }
}

export default YouWon;