import React, { Component, Fragment } from 'react';
import { TweenMax, Power3 } from 'gsap';
import { NavLink } from 'react-router-dom';
import InjectIntl from 'react-intl-inject';
import ReactGA from 'react-ga';
import Cookie from 'js-cookie';

import '../../styles/pages/_homepage.scss';

//import image
import EnterBtn from '../../images/enter-btn.png';
import EnterBtnHover from '../../images/enter-btn-hover.png';
import keyImage from '../../../src/images/key-isolated-noglow.png';
import translate from '../../i18n/translate';
import packshot from '../../../src/images/packages.png';
import packshotfr from '../../../src/images/packages-fr.png';
import step2 from '../../../src/images/step2.png';
import step3 from '../../../src/images/step3.png';
import step2fr from '../../../src/images/Entry-Homepage-Step 2-FR.png';
import step3fr from '../../../src/images/Entry-Homepage-Step 3-FR.png';

class Homepage extends Component {

    state = {
        headerHeight : 0,
        isHover : false
    }

    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        const headerHeight = document.getElementById("main-header").clientHeight;
        this.setState({headerHeight});

        //add class to front page
        document.querySelector('#glow').classList.add('homepage')

        //GSAP animation for the heading elements
        const { headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText } = this;
        TweenMax.from('#glow',.9,{scale:0});
        TweenMax.staggerFrom([headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText, '.ckey'], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut
        },.1)
    }

    componentWillUnmount(){
        //remove class from front page
        document.querySelector('#glow').classList.remove('homepage')
        document.querySelector('#glow').removeAttribute("style"); // clear out tweenmax scaling
    }

    render(){
        const langPreferance = Cookie.get('lang-preferance');
        return (
            <Fragment>
                <div className="main-wrapper" style={{marginTop: this.state.headerHeight}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 home-text-container text-center"> {/*before it was col-md-6*/}
                                <div className="heading-text">
                                    <h1 className="unlock-the-secret" ref={el => {this.headingOne = el}}>{translate('homepage.unlock')}</h1>
                                    <h1 className="chance-to-win" ref={el => {this.headingTwo = el}}><em>{translate('homepage.unlock.italic')}</em> {translate('homepage.win')}</h1>
                                    <h1 className="prize-money" ref={el => {this.headingThree = el}}>{translate('homepage.oneofthree',{sup: chunks => <sup>{chunks}</sup>,small: chunks => <small>{chunks}</small>})}</h1>
                                    <h1 className="prize-money" ref={el => {this.headingFour = el}}>{translate('homepage.prize',{sup: chunks => <sup>{chunks}</sup>})}</h1>
                                    <h1 className="plus-cash" ref={el => {this.headingFive = el}}>{translate('homepage.pluscash')}</h1>
                                </div>
                                <div className="coming-soon-key-container d-md-none">
                                    <InjectIntl>
                                        {({ intl }) => (
                                            <img src={keyImage} className="ckey" alt={intl.formatMessage({ id: 'caramilk.key' })} />
                                        )}
                                    </InjectIntl>
                                    {/* <img src={keyImage} className="ckey" alt="caramilk key" /> */}
                                </div>
                                <p className="home-body-text">{translate('enterContestText', {br: <br/>})}</p>
                                <div className="enter-contest-btn">
                                    <NavLink exact activeClassName="selectedLink" to='/enter-contest' tabIndex="0">
                                        <button className="enter-btn" tabIndex="0">
                                            <img src={EnterBtn} alt="" className="fill" />
                                            <img src={EnterBtnHover} alt="" className="hover" />
                                            <p>{translate('enterNow')}</p>
                                        </button>
                                    </NavLink>
                                </div>
                                <div className="learn-more-container">
                                    <a href="#howtoenter">
                                        {translate('homepage.cta')}
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-4 d-none d-md-block coming-soon-key-container">{/*before it had offset-md-1*/}
                                <InjectIntl>
                                    {({ intl }) => (
                                        <img src={keyImage} className="img-fluid ckey" alt={intl.formatMessage({ id: 'caramilk.key' })} />
                                    )}
                                </InjectIntl>
                                {/* <img src={keyImage} alt="" className="img-fluid ckey" /> */}
                            </div>
                        </div>
                        <div className="row" id="howtoenter">
                            <div className="col-md-12">
                                <h2>{translate('homepage.howtoenter')}</h2>
                                <h3>{translate('homepage.step1head')}</h3>
                                <p>{translate('homepage.step1')}</p>
                                <div className="packshot-group stepimage">
                                    {langPreferance === 'fr-CA' ? <img src={packshotfr} className="packshot" alt="caramilk packs" /> : <img src={packshot} className="packshot" alt="caramilk packs" />}
                                </div>
                                <h3>{translate('homepage.step2head')}</h3>
                                <p>{translate('homepage.step2', {br: <br />})}</p>
                                <img src={langPreferance === 'fr-CA' ? step2fr : step2} className="stepimage" alt="enter a pin code" />
                                <h3>{translate('homepage.step3head')}</h3>
                                <p>{translate('homepage.step3')}</p>
                                <img src={langPreferance === 'fr-CA' ? step3fr : step3} className="stepimage" alt="enter a pin code" />
                                <div className="enter-contest-btn">
                                    <NavLink exact activeClassName="selectedLink" to='/enter-contest' tabIndex="0">
                                        <button className="enter-btn" tabIndex="0">
                                            <img src={EnterBtn} alt="" className="fill" />
                                            <img src={EnterBtnHover} alt="" className="hover" />
                                            <p>{translate('enterNow')}</p>
                                        </button>
                                    </NavLink>
                                </div>
                                <div className="enter-contest-btn">
                                    <NavLink exact activeClassName="selectedLink" to='/pastwinners' tabIndex="0">
                                        <button className="enter-btn" tabIndex="0">
                                            <img src={EnterBtn} alt="" className="fill" />
                                            <img src={EnterBtnHover} alt="" className="hover" />
                                            <p>{translate('view2020winner')}</p>
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container disclaimer-wrap">
                        <div className="row">
                            <div className="col-md-12 disclaimer">
                                <p>{translate('legal')} <NavLink exact to="/rules">{translate('legal.link')}</NavLink></p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        );
    }
}

export default Homepage;