import React, { Component, Fragment } from 'react';
import { TweenMax, Power3 } from 'gsap';
import { NavLink } from 'react-router-dom';
import InjectIntl from 'react-intl-inject';
import ReactGA from 'react-ga';
import Cookie from 'js-cookie';

import '../../styles/pages/_homepage.scss';

//import image
// import EnterBtn from '../../images/enter-btn.png';
// import EnterBtnHover from '../../images/enter-btn-hover.png';
import keyImage from '../../../src/images/key-isolated-noglow.png';
import translate from '../../i18n/translate';
import insta from '../../../src/images/header-instagram.png';

class ContestClosed extends Component {

    state = {
        headerHeight : 0,
        isHover : false
    }

    //Handle Mouse over event for enter now button
    mouseEnter = () =>{
        this.setState(prevState =>{
            return {isHover: !prevState.isHover}
        })
    }

    //HANDLE MOUSE LEAVE ON THE ENTER NOW BUTTON
    mouseLeave = () =>{
        this.setState(prevState =>{
            return {isHover: !prevState.isHover}
        })
    }


    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        const headerHeight = document.getElementById("main-header").clientHeight;
        this.setState({headerHeight});

        //add class to front page
        document.querySelector('#glow').classList.add('homepage')

        //GSAP animation for the heading elements
        const { headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText } = this;
        TweenMax.from('#glow',.9,{scale:0});
        TweenMax.staggerFrom([headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText, '.ckey'], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut
        },.1)
    }

    componentWillUnmount(){
        //remove class from front page
        document.querySelector('#glow').classList.remove('homepage')
        document.querySelector('#glow').removeAttribute("style"); // clear out tweenmax scaling
    }

    render(){
        const langPreferance = Cookie.get('lang-preferance');
        return (
            <Fragment>
                <div className="main-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 home-text-container text-center"> {/*before it was col-md-6*/}
                            <div className="heading-text">
                                    <h1 className="unlock-the-secret" ref={el => {this.headingOne = el}}>{translate('homepage.unlock')}</h1>
                                    <h1 className="chance-to-win" ref={el => {this.headingTwo = el}}><em>{translate('homepage.unlock.italic')}</em> {translate('homepage.win')}</h1>
                                    <h1 className="prize-money" ref={el => {this.headingThree = el}}>{translate('homepage.oneofthree',{sup: chunks => <sup>{chunks}</sup>,small: chunks => <small>{chunks}</small>})}</h1>
                                    <h1 className="prize-money" ref={el => {this.headingFour = el}}>{translate('homepage.prize',{sup: chunks => <sup>{chunks}</sup>})}</h1>
                                    <h1 className="plus-cash" ref={el => {this.headingFive = el}}>{translate('homepage.pluscash')}</h1>
                                </div>
                                <div className="coming-soon-key-container d-md-none">
                                    <InjectIntl>
                                        {({ intl }) => (
                                            <img src={keyImage} className="ckey" alt={intl.formatMessage({ id: 'caramilk.key' })} />
                                        )}
                                    </InjectIntl>
                                    {/* <img src={keyImage} className="ckey" alt="caramilk key" /> */}
                                </div>
                                <h2 className="home-header">{translate('contestClosedHead', {br: <br/>})}</h2>
                                <p className="home-body-text">{translate('contestClosedText', {br: <br/>})}</p>
                                <a href={langPreferance === 'fr-CA' ? 'https://www.instagram.com/caramilkcanada/?hl=en' : 'https://www.instagram.com/caramilkcanada/?hl=en'} target="_blank" rel="noopener noreferrer"><img src={insta} alt="instagram" className="insta-link" /></a>
                            </div>
                            <div className="col-md-4 d-none d-md-block coming-soon-key-container">{/*before it had offset-md-1*/}
                                <InjectIntl>
                                    {({ intl }) => (
                                        <img src={keyImage} className="img-fluid ckey" alt={intl.formatMessage({ id: 'caramilk.key' })} />
                                    )}
                                </InjectIntl>
                                {/* <img src={keyImage} alt="" className="img-fluid ckey" /> */}
                            </div>
                        </div>
                    </div>
                    <div className="container disclaimer-wrap">
                        <div className="row">
                            <div className="col-md-12 disclaimer">
                                <p>{translate('legal')} <NavLink exact to="/rules">{translate('legal.link')}</NavLink></p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        );
    }
}

export default ContestClosed;