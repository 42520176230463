import React, { Component, Fragment } from 'react';
import { TweenMax, Power3 } from 'gsap';
import { TextField } from '@material-ui/core';
import InjectIntl from 'react-intl-inject';
import ReactGA from 'react-ga';
import Cookie from 'js-cookie';

//langualge file
import translate from '../../i18n/translate';

//key image
import Key from '../../images/youwin-key.png';
//buttons
import EnterBtn from '../../images/enter-btn.png';
import EnterBtnHover from '../../images/enter-btn-hover.png';
import pinFormPopup from '../../images/pin-form-popup.png';
import pinFormPopupImageEN from '../../images/pin-form-popup-image-en.png';
import pinFormPopupImageFR from '../../images/pin-form-popup-image.png';
import closeBtn from '../../images/close-btn.png';

class PinForm extends Component{

    state = {
        buttonHover : false,
        popupVisible: false,
        pinError: ''
    }

    //Handle Mouse over event for enter now button
    mouseEnter = () =>{
        this.setState(prevState =>{
            return {buttonHover: !prevState.buttonHover}
        })
    }

    //HANDLE MOUSE LEAVE ON THE ENTER NOW BUTTON
    mouseLeave = () =>{
        this.setState(prevState =>{
            return {buttonHover: !prevState.buttonHover}
        })
    }

    //pin form help popup
    popupClickHandle = () => {
        this.setState({
            popupVisible: true
        })

        //setting the position of the popup box
        let popUp = document.querySelector('.pinFormPopup');
        let heightOfThePopup = popUp.offsetHeight;
        let heightOfTheBottomArrow = 21.21;
        let topValueOfPopup = heightOfThePopup + heightOfTheBottomArrow;
        popUp.style.top = -topValueOfPopup + 'px';
    }

    //popup close button click
    popupCloseButtonHandle = () => {
        this.setState({
            popupVisible: false
        })
    }

    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        const form = this.form;

        TweenMax.from(form, .6, {
            opacity:1,
            x: -300,
            ease: Power3.easeOut
        })
    }

    render(){

        const { values, nextStep, handleChange, inputPinClickHandler, errorMessage, inputKeypressHandler } = this.props;
        const langPreferance = Cookie.get('lang-preferance');

        return(
            <Fragment> 
                <div className="main-wrapper">
                    <div className="container pin-form__container">
                        <div className="steps">
                            <p>{translate('step1')}</p>
                            <div className="steps__progress">
                                <div className="step one"></div>
                                <button onClick={nextStep} className="step two"></button>
                            </div>
                        </div>
                        <h1 className="form__heading pin-form__heading">{translate('enterPin')}</h1>
                        <p className="form__description pin-form__description">{translate('enterKeyDescription')}</p>
                        <div className="enter-pin__key">
                            <InjectIntl>
                                {({ intl }) => (
                                    <img src={Key} alt={intl.formatMessage({ id: 'caramilk.key' })} />
                                )}
                            </InjectIntl>
                        </div>

                        

                        <div className="mui-container pin-form" ref={el => this.form = el}>
                            <form onSubmit={nextStep}>

                                <div className={this.state.popupVisible ? 'pinFormPopup visible' : 'pinFormPopup'}>
                                    <div className="pinFormPopup--container">
                                        <div className="pinFormPopup__closeButton" onClick={this.popupCloseButtonHandle}><img src={closeBtn} alt="close button"/></div>
                                        <div className="pinFormPupup__content">
                                            <p>{translate('popupBodytext')}</p>
                                            <img src={langPreferance === 'fr-CA' ? pinFormPopupImageFR : pinFormPopupImageEN} alt="Pin form popup figure"/>
                                        </div>
                                    </div>
                                </div>

                                <TextField className={errorMessage ? 'error' : ''} variant="filled" required label={translate('pin')} defaultValue={values.pin} onChange={handleChange('pin')} onClick={inputPinClickHandler} helperText={errorMessage} onKeyPress={inputKeypressHandler} />
                                <div className="pinFormPopupButton" onClick={this.popupClickHandle}><img src={pinFormPopup} alt="caramilk pin help"/></div>
                                <button type="submit" className="pin-form__submit-btn" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave} onClick={nextStep} disabled={values.pin !== "" ? false : true} >
                                    <img src={EnterBtn} alt="enter contest button" className={this.state.buttonHover ? 'hide' : 'show'} />
                                    <img src={EnterBtnHover} alt="enter contest button" className={this.state.buttonHover ? 'show' : 'hide'} />
                                    <p>{translate('nextStep')}</p>
                                </button>
                            </form>
                        </div>

                    </div>
                </div>
            </Fragment>
        )
    }
}

export default PinForm;