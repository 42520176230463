import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { TweenMax, Power3 } from 'gsap';
// import InjectIntl from 'react-intl-inject';
import ReactGA from 'react-ga';

//import css
import '../../styles/pages/_prize-details.scss';

//langualge file
import translate from '../../i18n/translate';

import { getContestState } from '../../util';

import prizeImage1 from '../../images/money-bags.png';
import prizeImage1fr from '../../images/money-bags-fr.png';
import prizeImage2 from '../../images/money-key-EN.png';
import prizeImage2fr from '../../images/money-key-FR.png';
import EnterBtn from '../../images/enter-btn.png';
import EnterBtnHover from '../../images/enter-btn-hover.png';

class PrizeDetails extends Component{

    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        //GSAP animation for the heading elements
        const { heading, prize1, prize1desc, prize2, prize2desc } = this;
        TweenMax.staggerFrom([heading, prize1, prize1desc, prize2, prize2desc], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut
        },.2)
    }

    render(){
        const contestState = getContestState();
        return(
            <Fragment>
                <div className="main-wrapper prize-details">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <div className="page-heading">
                                        <h1 ref={el =>{this.heading = el}}>{translate('prizeDetails')}</h1>
                                    </div>
                                    <div className="row prize-details__text-section grand-prize">
                                        <div className="col-md-6" ref={el => {this.prize1desc = el}}>
                                            <h2 style={{fontSize: '60px'}}>{translate('prizeDetails-heading1')}</h2>
                                            <p style={{fontSize: '22px'}}>{translate('prizeDetails-subheading1')}</p>
                                            <p>{translate('prizeDetails-body1',{li:chunk=><li>{chunk}</li>})}</p>
                                        </div>
                                        <div className="col-md-6 img left-overlap">
                                            <div>{this.props.lang === 'fr-CA' ? <img src={prizeImage1fr} ref={el => {this.prize1 = el}} alt="100 000 $"/> : <img src={prizeImage1} ref={el => {this.prize1 = el}} alt="$100,000"/>}</div>
                                        </div>
                                    </div>
                                    <div className="row prize-details__text-section">
                                        <div className="col-md-6 img right-overlap">
                                            <div>{this.props.lang === 'fr-CA' ? <img src={prizeImage2fr} ref={el => {this.prize2 = el}} alt="Cash prizes!"/> : <img src={prizeImage2} ref={el => {this.prize2 = el}} alt="Cash prizes!"/>}</div>
                                        </div>
                                        <div className="col-md-6" ref={el => {this.prize2desc = el}}>
                                            <h2>{translate('prizeDetails-heading2')}</h2>
                                            <p>{translate('prizeDetails-body2', {br : <br />})}</p>
                                        </div>
                                    </div>
                                    { contestState===0 && <div className="enter-contest-btn">
                                        <NavLink exact to="/enter-contest">
                                            <button className="enter-btn" tabIndex="0">
                                                <img src={EnterBtn} alt="enter contest button" className="fill" />
                                                <img src={EnterBtnHover} alt="enter contest button" className="hover" />
                                                <p>{translate('enterNow')}</p>
                                            </button>
                                        </NavLink>
                                    </div> }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }
}

export default PrizeDetails;